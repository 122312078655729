import React from 'react';
import ChristmasTreeSVG  from '../assets/ChristmasTree.svg'

const ChristmasTree = () => {
    return (
        <div className="container">
            <img src={ChristmasTreeSVG} alt="Christmas Tree" />
        </div>
    );
};

export default ChristmasTree;
