import './App.css';
import CountdownTimer from './components/CountdownTimer';
import Snowfall from 'react-snowfall';
import ChristmasTree from './components/ChristmasTree';
import PhotoGallery from './components/PhotoGallery';
import image1 from "./assets/IMG_1794.JPG";
import image2 from "./assets/IMG_2344.JPG";
import image3 from "./assets/IMG_2347.JPG";
import image4 from "./assets/IMG_2353.JPG";
import image5 from "./assets/IMG_6127.jpg";
import image6 from "./assets/IMG_6253.jpeg";
import image7 from "./assets/IMG_6383.jpg";
import image8 from "./assets/IMG_7191.JPG";
import image9 from "./assets/IMG_8379.JPEG";
import image10 from "./assets/IMG_8466.JPG";
import image11 from "./assets/IMG_8811.jpeg";
import image12 from "./assets/IMG_8814.JPEG";
import image13 from "./assets/IMG_9837.jpg";


function App() {
    const galleryImages = [image1, image2,image3,image4, image5,image6,image7, image8,image9 ,image10, image11, image12, image13];

    return (
        <div className="App">
                <div className="snowfall-container">
                    <Snowfall />
                </div>
                <section className="fullpage countdown">
                    <CountdownTimer />
                </section>
                <section className="fullpage christmas-tree">
                    <ChristmasTree />
                </section>
                <section className="fullpage photo-gallery-section">
                    <PhotoGallery images={galleryImages} />
                </section>
            </div>
    );
}

export default App;
