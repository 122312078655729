import React, { useState, useEffect } from 'react';
import './CountdownTimer.css';
import confetti from 'canvas-confetti'; // Ensure you have installed canvas-confetti

const CountdownTimer = () => {
    const calculateTimeLeft = () => {
        const christmasDay = new Date('Dec 25, 2023');
        const currentTime = new Date();
        const difference = christmasDay - currentTime;

        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            const updatedTimeLeft = calculateTimeLeft();
            setTimeLeft(updatedTimeLeft);

            if (Object.values(updatedTimeLeft).every(value => value <= 0) && !isFinished) {
                setIsFinished(true);
                launchConfetti();
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [isFinished]);

    const launchConfetti = () => {
        confetti({
            angle: 90,
            spread: 360,
            startVelocity: 30,
            elementCount: 200, // More particles
            dragFriction: 0.12,
            duration: 3000, // Longer duration
            stagger: 3,
            width: "10px",
            height: "10px",
            colors: ['#bb0000', '#ffffff', '#00bb00'], // Added green for variety
        });
    };

    if (isFinished) {
        return (
            <div className="countdown-finished">
                <h2>Merry Christmas!</h2>
            </div>
        );
    }

    return (
        <div className="countdown-container">
            <h2 className="countdown-title">Countdown to Christmas</h2>
            <div className="countdown">
                {Object.keys(timeLeft).map((interval) => (
                    <div key={interval} className="countdown-item">
                        <span className="countdown-time">{timeLeft[interval]}</span>
                        <span className="countdown-label">{interval}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CountdownTimer;
