// PhotoGallery.js
import React from 'react';
import './PhotoGallery.css';

const PhotoGallery = ({ images }) => {
    const getRotation = () => Math.random() * 10 - 5; // Random rotation between -5 and 5 degrees

    return (
        <div className="photo-gallery">
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Gallery item ${index}`}
                    style={{ transform: `rotate(${getRotation()}deg)` }}
                    className="gallery-image"
                />
            ))}
        </div>
    );
};

export default PhotoGallery;
